import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { saveActivity } from "@actions/activity";
import { getUserInfo } from "@actions/auth";

// import ResponsePageModal from "./ResponsePageModal";
import MaterialTextarea from "@components/main/MaterialTextarea";
import history from "../.../../../../history";
import firebase from 'firebase/app';
import Swal from "sweetalert"


import "./style.css";

class GuestResponsePage extends Component {
  state = {
    response: "",
    name: "",
    user_id: 'Aho997qhycOYEVBO36Uq2Cqn0cw1',
    group_id: null,
    question: null,
    image_url: window.localStorage.getItem("setImage_url"),
    hideNameFromGroup: false,
    hideResponseFromGroup: false,
    open: false,

    question_count : 1,
    question_total: 1

  };

  componentDidMount() {
    if (!JSON.parse(localStorage.getItem("selectedQuestion"))) {
      history.push("/");
    } else {
      this.setState({
        question: JSON.parse(localStorage.getItem("selectedQuestion")).content,
      });
    }
  }

  componentWillMount = async () => {
    if (localStorage.getItem("activity_type") === "group") {
      this.setGroup();
    }


    if(localStorage.getItem('question_count')){
      this.setState({
        question_count: localStorage.getItem('question_count')
      });

    }

    if(localStorage.getItem('question_total')){
      this.setState({
        question_total: localStorage.getItem('question_total')
      });
    }

    if(localStorage.getItem('guest_name')){
      this.setState({
        name: localStorage.getItem('guest_name')
      });
    }

  };

  setGroup = () => {
    if (window.localStorage.getItem("group")) {
      this.setState({
        group_id: JSON.parse(window.localStorage.getItem("group"))[0].groupKey,
      });
    }
  };

  handleChange = (event) => {
    const { value, maxLength } = event.target;
    let message = '';
    if ( [event.target.name] === "response" ){
        message = value.slice(0, maxLength);
    }else{
        message = value;
    }
    this.setState({ [event.target.name]: message });
  };

  

  componentWillReceiveProps = (newProps) => {
    if (newProps.activityCreate) {
      this.handleSuccess();
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // this.props.saveActivity(this.state);
    alert(1);
    firebase.firestore().collection("activities").add(
    {
        user_id : '',
        activity_type: 'group',
        name: this.state.name,
        question: this.state.question,
        group_id: this.state.group_id,
        response: this.state.response,
        image_url: this.state.image_url,
        hideNameFromGroup: false,
        hideResponseFromGroup: false,
        createdAt: new Date()
    })


  };

  handleSuccess = () => {
    window.localStorage.removeItem("selectedQuestion");
    window.localStorage.removeItem("setImage_url");
    this.state.group_id == null
      ? history.push({
        pathname: "/results-personal",
        state: { personal_image: this.state },
      })
      : history.push({
        pathname: `/results-team/in-activity/${this.state.group_id}`,
      });
  };

  handleModal = (event) => {
    event.preventDefault();
    if (this.state.response && this.state.name) {


      if ( this.state.response.length > 500 ){
          Swal("",'Response must be less than 500 characters!','error');
      }else{

        //if it is a multiple question group; save data in local storage and send to next question
        if( Array.isArray( JSON.parse(window.localStorage.getItem("group"))[0].question_id ) ){

          // store name in local storage
          localStorage.setItem("guest_name", this.state.name);

          //store this questions data in local storage
          var this_question = JSON.parse(localStorage.getItem("selectedQuestion")).content;
          var this_question_id = JSON.parse(localStorage.getItem("selectedQuestion")).key;
          var this_image_url=  window.localStorage.getItem("setImage_url");

          var thisQuestionResponse = {}

          thisQuestionResponse = {
            question_id: this_question_id,
            question: this_question,
            image_url: this_image_url,
            response: this.state.response
          }
          

          //get previous multipleQuestionResponse
          var multipleQuestionResponse = JSON.parse(localStorage.getItem("multipleQuestionResponse"));

          //check if all questions belong to this group; or it can saved from other group
          if(multipleQuestionResponse && Array.isArray(multipleQuestionResponse.answers) 
            && multipleQuestionResponse.group_id == this.state.group_id){

            multipleQuestionResponse.answers.push(thisQuestionResponse);

          }else{

            multipleQuestionResponse = {};
            multipleQuestionResponse.group_id = this.state.group_id;
            multipleQuestionResponse.answers = [];
            multipleQuestionResponse.answers.push(thisQuestionResponse);

          }

          //save response in local storage;
          localStorage.setItem("multipleQuestionResponse", JSON.stringify(multipleQuestionResponse));

          //check if all questions have responses
          var question_ids = JSON.parse(window.localStorage.getItem("group"))[0].question_id;

          var final_response = true;
          
          question_ids.forEach(question_id => {

            var found = false;
            
            multipleQuestionResponse.answers.forEach( (localAnswer)=>{
              if(localAnswer.question_id == question_id){
                found = true;
              }
            });  

            //this question_id was not answered; send user for it's answer;
            if(!found){
              final_response = false;
              history.push("/guest-activity/question");
              return;
            }
            
          });

          if(final_response){

            // all questions are done. store in firebase.
            firebase.firestore().collection("activities").add(
            {
                user_id : '',
                activity_type: 'group',
                name: this.state.name,
                question: this.state.question,
                group_id: this.state.group_id,
                multipleQuestionResponse: multipleQuestionResponse,
                response: this.state.response,
                image_url: this.state.image_url,
                hideNameFromGroup: false,
                hideResponseFromGroup: false,
                createdAt: new Date()
            })

            // clear local storage
            if (localStorage.getItem("multipleQuestionResponse") !== null) {
              localStorage.removeItem("multipleQuestionResponse"); 
            }
            if (localStorage.getItem("question_count") !== null) {
                localStorage.removeItem("question_count");
            }
            if (localStorage.getItem("question_total") !== null) {
                localStorage.removeItem("question_total");
            }
            if (localStorage.getItem("selectedQuestion") !== null) {
                localStorage.removeItem("selectedQuestion");
            }
            if (localStorage.getItem("guest_name") !== null) {
              localStorage.removeItem("guest_name");
            }

            // success message
            Swal("Success",'Responses successfully saved!','success', {button: "Okay"})
            .then((value) => {
                if (`${value}` === 'true'){
                    history.push("/");
                }
            });



          }

        }else{ 
          //it is not multiple question group.
          
          // store in firebase
          firebase.firestore().collection("activities").add(
            {
                user_id : '',
                activity_type: 'group',
                name: this.state.name,
                question: this.state.question,
                group_id: this.state.group_id,
                response: this.state.response,
                image_url: this.state.image_url,
                hideNameFromGroup: false,
                hideResponseFromGroup: false,
                createdAt: new Date()
            })
          
          // success message
          Swal("Success",'Response successfully saved!','success', {button: "Okay"})
          .then((value) => {
              if (`${value}` === 'true'){
                  history.push("/");
              }
          });

        }


      }

    }else{
        Swal("",'Please fill all fields!','error');
    }
  };

  render() {
    const image_url = window.localStorage.getItem("setImage_url");
    const { activity_type, question_key } = this.props.match.params;
    return (
      <div id="main">
        <div className="container mobtop">
          <div className="row">
            <div className="col-md-12 back-nav">
              <Link
                to={`/guest-activity/saved-images/${question_key}`}
                className="goto_back"
              >
                <i></i>Back
              </Link>
            </div>
            <div className="progress-steps">
              <span className="step step-1 active"></span>
              <span className="step step-2 active"></span>
              <span className="step step-3 active"></span>
              <span className="step step-4 active"></span>
              <span className="step step-5 active"></span>
            </div>
            <div className="buttons-padding-box padding-box">
              <div
                className="buttons-wrap box-wrap small-box"
                style={{ borderRadius: 5 }}
              >
                <div className="resp-title">
                  WHAT VISUALLY ATTRACTED YOU TO THIS IMAGE, AND EXPLAIN HOW THE
                  SELECTED IMAGE SYMBOLIZES THIS: {this.state.question}
                </div>
                <form className="resp-form entry-form check-form small-form">
                 
                  
                  <MaterialTextarea
                    label="Your Response"
                    onChange={this.handleChange}
                    required={true}
                    name="response"
                    maxLength="500"
                  />

                  { !localStorage.getItem('guest_name') &&
                  <>
                  <p style={{ marginBottom: "0px", fontSize: "16px", color: "#191919", marginTop: "30px" }}>Your Name</p>
                  <input
                    type="text"
                    style={{ borderBottom: "1px solid #505050", marginBottom: "40px" }}
                    label="Your Name"
                    onChange={this.handleChange}
                    required={true}
                    name="name"
                    defaultValue={this.state.name}
                  />
                  </>
                  }
                  
                  <div className="fimg-holder" style={{ textAlign: "center" }}>
                    <img
                      src={image_url}
                      title="Final Image"
                      className="final-image response-image"
                      alt="loading"
                    />
                  </div>
                  {this.state.group_id ? (
                    <Fragment>
                      <div className="button-wrap">
                        <button
                          className="btn-primary active"
                          onClick={this.handleModal}
                        >
                          SUBMITT
                        </button>
                      </div>
                    </Fragment>
                  ) : (
                      <div className="button-wrap">
                        <button
                          className="btn-primary active"
                          onClick={this.handleSubmit}
                        >
                          SUBMITT
                      </button>
                      </div>
                    )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { firebase, activity } = state;

  return {
    uid: firebase.auth.uid,
    activityCreate: activity.activityCreate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveActivity: (activity) => dispatch(saveActivity(activity)),
    getUserInfo: (uid) => dispatch(getUserInfo(uid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestResponsePage);
