import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getGroupByGroupKey, refreshGroupErrors } from "@actions/group";

import HowItWorks from "../../main/HowItWorks";
import MaterialInput from "../../main/MaterialInput";

import "./team-activity.css";

class TeamActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupKey: "",
      showAlert: false,
      showHowItWorks: false,
      groupItem: null,
    };
  }

  componentWillMount() {

    window.localStorage.setItem("activity_type", "group");

    if (localStorage.getItem("multipleQuestionResponse") !== null) {
      localStorage.removeItem("multipleQuestionResponse");
    }
    if (localStorage.getItem("question_count") !== null) {
      localStorage.removeItem("question_count");
    }
    if (localStorage.getItem("question_total") !== null) {
      localStorage.removeItem("question_total");
    }
    if (localStorage.getItem("selectedQuestion") !== null) {
      localStorage.removeItem("selectedQuestion");
    }

  }

  componentWillUnmount() {
    this.props.refreshGroupErrors();
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps) {
      this.setState({
        showAlert: newProps.error !== null ? true : false,
        showHowItWorks:
          newProps.group[0] && newProps.error == null ? true : false,
      });
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.getGroupByGroupKey(this.state.groupKey);
  };

  render() {
    const error_style = {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "1.71",
      letterSpacing: "normal",
      color: "#ef2727",
      position: "relative",
      bottom: "14px",
    };

    const howItWorks = (
      <div className="buttons-padding-box padding-box">
        <div className="buttons-wrap box-wrap">
          <HowItWorks
            startLink="/team-activity/question"
            content={`
                        <p>
                            You will be presented with a question that we will ask you to reflect on. 
                            You will then look through a series of photos selecting the ones that match up with your reflection question.
                        </p>
                        <p>
                            Once you have viewed all the images, you will be asked to select a final image from your saved list that best represents your vision of the question.
                            <br/><br/>
                            We will then ask you to briefly explain why the image chosen best represents your vision of the selected question.
                        </p>
                        <p>Once completed, you will be able to share your result, and view the results of your entire team.</p> 
                    `}
          />
        </div>
      </div>
    );

    const setGroupId = (
      <div className="buttons-padding-box padding-box">
        <div className="buttons-wrap box-wrap" style={{ borderRadius: 5 }}>
          <form
            onSubmit={this.handleSubmit}
            className="team-form entry-form check-form small-form "
          >
            <MaterialInput
              type="text"
              name="groupKey"
              value={this.state.groupKey}
              id="groupKey"
              onChange={this.handleChange}
              required={true}
              label="Group ID"
              error={this.state.showAlert}
              placeholder="Group ID"
            />
            {this.state.showAlert && (
              <div style={error_style}>{this.props.error}</div>
            )}
            <div className="button-wrap">
              <button
                className={
                  "btn-primary btn-team " +
                  (this.state.groupKey ? "active" : "inactive")
                }
              >
                ENTER
              </button>
            </div>
          </form>
          <Link
            to="/personal-use/question"
            id="new_ques"
            className="btn-primary-bdr mt-20"
          >
            PROCEED WITHOUT GROUP ID
          </Link>
        </div>
      </div>
    );

    return (
      <div id="main">
        <div className="container">
          <div className="row">
            <div className="col-md-12 back-nav">
              <Link to="/dashboard" className="goto_back">
                <i></i>Back
              </Link>
            </div>
            {this.state.showHowItWorks ? howItWorks : setGroupId}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { group, error } = state.group;
  const { profile } = state.firebase;
  return {
    group,
    profile,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGroupByGroupKey: (groupKey) => dispatch(getGroupByGroupKey(groupKey)),
  refreshGroupErrors: () => dispatch(refreshGroupErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamActivity);
