import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import pptxgen from "pptxgenjs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TagCloud from "react-tag-cloud";
import randomColor from "randomcolor";
import "jspdf-autotable";
import { deleteResponse, getActivittiesGroups } from '@actions/group';
import Swal from "sweetalert"
import ReactWordcloud from 'react-wordcloud';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
} from "reactstrap";
import { firestoreConnect } from "react-redux-firebase";
import { CSVLink } from "react-csv";

import history from "../../../history";
import ReactTable from "react-table";
import { toast } from "react-toastify";

import "./styles.css";
import { result } from "lodash";

class ViewResultsTable extends Component {
  constructor() {
    super();

    this.state = {
      open: false,
      queston: "",
      response: "",
      detailModal: false,
      detailData: "",
      modal: false,
      responseModal: false,
      tagWords: [],
    };
  }
  componentDidMount = () => {

    window.$('.modal-content').css('width', "100%");

  }
  componentWillMount = () => {
    this.props.getActivittiesGroups(this.props.query, this.props.resultsKey);

  }
  closeModal = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  checkDetails = (value) => {
    if (value.target.dataset.activity) {

      let activity = JSON.parse(value.target.dataset.activity);
      this.setState({
        detailModal: !this.state.detailModal,
        detailData: activity,
      });

    } else {
      this.setState({
        detailModal: !this.state.detailModal,
      });
    }
  };
  deleteResponse = (value) => {

    value.preventDefault();
    let delete_id = value.target.dataset.id;

    Swal("", 'Are you sure you want to delete this result?', { buttons: ["Cancel", "Delete"] })
      .then((choose) => {

        if (`${choose}` === 'true') {

          this.props.deleteResponse(delete_id);
          document.querySelectorAll('[data-id="' + delete_id + '"]')[0].parentElement.parentElement.parentElement.style.display = "none";
          toast("Deleted Successfuly", { type: "success" });

        }
      });
    window.$(".swal-text").css({ "text-align": "left", "display": "block", "margin-left": "10px", "margin-top": "20px" });



  }

  onClickImage = (event) => {
    event.preventDefault();
    let activities = JSON.parse(event.target.dataset.activity);
    if (activities.group_id) {
      history.push({
        pathname: `/results-team-admin/admin/${activities.group_id}`,
      });
    } else {
      this.setState({
        question: activities.question,
        response: activities.response,
        image_url: activities.image_url,
        open: !this.state.open,
      });
    }
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  // getGroupActivities
  downloadSlides = () => {
    toast("Downloading", { type: "info" });
    const { activities } = this.props;
    const filename = "GroupSlides.pptx";
    const pptx = new pptxgen();
    activities.map((result) => {
      let str = result.response;
      if (str.length > 500) {
        str = str.substring(0, 500)
        str = `${str} ...`
      }

      const slide = pptx.addSlide();

      let name = 'by ' + result.name
      slide.addText(name, {
        x: 3.8,
        y: 3.8,
        w: 5,
        color: "000000",
        fontSize: 14,
        fontFace: 'sans-serif'
      });


      const img = new Image();
      img.src = result.image_url;
      // let new_width = img.width/img.height * 2;
      // slide.addImage({ path: result.image_url, x: 0.5, y: 1.3, w: new_width, h: 2 });

      let new_height = img.height / img.width * 3;

      if (new_height <= 3.3) {
        slide.addImage({ path: result.image_url, x: 0.5, y: 1, w: 3, h: new_height });
      } else {
        let new_width = img.width / img.height * 3.3;
        slide.addImage({ path: result.image_url, x: 0.5, y: 1, w: new_width, h: 3.3 });
      }

      slide.addImage({ path: "/assets/images/sparq_logo.png", x: 0.5, y: 0.3, w: 1.2, h: 0.3 });

      let arrTextObjs2 = [
        { text: "https://discoversparq.com", options: { fontSize: 12, color: "FFFFFF", align: "center", fontFace: 'sans-serif' } },
      ];
      slide.addText(arrTextObjs2, { x: 0, y: 4.8, w: 10, h: 0.8, fill: { color: "2a3381" } });

      let arrTextObjs3 = [
        { text: str, options: { fontSize: 14, color: "000000", align: "center", fontFace: 'sans-serif' } },
      ];
      slide.addText(arrTextObjs3, { x: 3.9, y: 1, w: 5.5, h: 2.5, fill: { color: "f2f2f2" } });

      //      let response = 'Response: '+str 
      //   slide.addText(response, {
      //     x: 5.5,
      //     y: 2,
      //     w: 3,
      //     color: "000000",
      //     fontSize: 16,
      //   });
      return result;

      //   const slide = pptx.addSlide();
      //   slide.addText(result.name, {
      //     x: 0.5,
      //     y: 0.7,
      //     w: 3,
      //     color: "000000",
      //     fontSize: 20,
      //   });
      //   slide.addText(str, {
      //     x: 0.5,
      //     y: 1.2,
      //     w: 10,
      //     color: "000000",
      //     fontSize: 16,
      //   });
      //     const img = new Image();
      //     img.src = result.image_url;
      //     let new_width = img.width/img.height * 3.5
      //     slide.addImage({ path: result.image_url, x: 3, y: 2, w: new_width, h: 3.5 });


      //   return result;
    });
    pptx.writeFile(filename);
  };

  downloadSlidesMultiple = () => {
    toast("Downloading", { type: "info" });
    const { activities } = this.props;
    const filename = "GroupSlides.pptx";
    const pptx = new pptxgen();
    activities.map((result) => {

      if (result.multipleQuestionResponse && Array.isArray(result.multipleQuestionResponse.answers)) {

        result.multipleQuestionResponse.answers.map((ans, indexAns) => {

          let str = ans.response;
          if (str.length > 500) {
            str = str.substring(0, 500)
            str = `${str} ...`
          }

          const slide = pptx.addSlide();

          let name = 'by ' + result.name
          slide.addText(name, {
            x: 3.8,
            y: 3.8,
            w: 5,
            color: "000000",
            fontSize: 14,
            fontFace: 'sans-serif'
          });


          const img = new Image();
          img.src = ans.image_url;
          // let new_width = img.width/img.height * 2;
          // slide.addImage({ path: result.image_url, x: 0.5, y: 1.3, w: new_width, h: 2 });

          let new_height = img.height / img.width * 3;

          if (new_height <= 3.3) {
            slide.addImage({ path: ans.image_url, x: 0.5, y: 1, w: 3, h: new_height });
          } else {
            let new_width = img.width / img.height * 3.3;
            slide.addImage({ path: ans.image_url, x: 0.5, y: 1, w: new_width, h: 3.3 });
          }

          slide.addImage({ path: "/assets/images/sparq_logo.png", x: 0.5, y: 0.3, w: 1.2, h: 0.3 });

          let arrTextObjs2 = [
            { text: "https://discoversparq.com", options: { fontSize: 12, color: "FFFFFF", align: "center", fontFace: 'sans-serif' } },
          ];
          slide.addText(arrTextObjs2, { x: 0, y: 4.8, w: 10, h: 0.8, fill: { color: "2a3381" } });

          let arrTextObjs3 = [
            { text: str, options: { fontSize: 14, color: "000000", align: "center", fontFace: 'sans-serif' } },
          ];
          slide.addText(arrTextObjs3, { x: 3.9, y: 1, w: 5.5, h: 2.5, fill: { color: "f2f2f2" } });

          //      let response = 'Response: '+str 
          //   slide.addText(response, {
          //     x: 5.5,
          //     y: 2,
          //     w: 3,
          //     color: "000000",
          //     fontSize: 16,
          //   });
          // return result;

          //   const slide = pptx.addSlide();
          //   slide.addText(result.name, {
          //     x: 0.5,
          //     y: 0.7,
          //     w: 3,
          //     color: "000000",
          //     fontSize: 20,
          //   });
          //   slide.addText(str, {
          //     x: 0.5,
          //     y: 1.2,
          //     w: 10,
          //     color: "000000",
          //     fontSize: 16,
          //   });
          //     const img = new Image();
          //     img.src = result.image_url;
          //     let new_width = img.width/img.height * 3.5
          //     slide.addImage({ path: result.image_url, x: 3, y: 2, w: new_width, h: 3.5 });


          //   return result;

        })

      } else {
        let str = result.response;
        if (str.length > 500) {
          str = str.substring(0, 500)
          str = `${str} ...`
        }

        const slide = pptx.addSlide();

        let name = 'by ' + result.name
        slide.addText(name, {
          x: 3.8,
          y: 3.8,
          w: 5,
          color: "000000",
          fontSize: 14,
          fontFace: 'sans-serif'
        });


        const img = new Image();
        img.src = result.image_url;
        // let new_width = img.width/img.height * 2;
        // slide.addImage({ path: result.image_url, x: 0.5, y: 1.3, w: new_width, h: 2 });

        let new_height = img.height / img.width * 3;

        if (new_height <= 3.3) {
          slide.addImage({ path: result.image_url, x: 0.5, y: 1, w: 3, h: new_height });
        } else {
          let new_width = img.width / img.height * 3.3;
          slide.addImage({ path: result.image_url, x: 0.5, y: 1, w: new_width, h: 3.3 });
        }

        slide.addImage({ path: "/assets/images/sparq_logo.png", x: 0.5, y: 0.3, w: 1.2, h: 0.3 });

        let arrTextObjs2 = [
          { text: "https://discoversparq.com", options: { fontSize: 12, color: "FFFFFF", align: "center", fontFace: 'sans-serif' } },
        ];
        slide.addText(arrTextObjs2, { x: 0, y: 4.8, w: 10, h: 0.8, fill: { color: "2a3381" } });

        let arrTextObjs3 = [
          { text: str, options: { fontSize: 14, color: "000000", align: "center", fontFace: 'sans-serif' } },
        ];
        slide.addText(arrTextObjs3, { x: 3.9, y: 1, w: 5.5, h: 2.5, fill: { color: "f2f2f2" } });

        //      let response = 'Response: '+str 
        //   slide.addText(response, {
        //     x: 5.5,
        //     y: 2,
        //     w: 3,
        //     color: "000000",
        //     fontSize: 16,
        //   });
        // return result;

        //   const slide = pptx.addSlide();
        //   slide.addText(result.name, {
        //     x: 0.5,
        //     y: 0.7,
        //     w: 3,
        //     color: "000000",
        //     fontSize: 20,
        //   });
        //   slide.addText(str, {
        //     x: 0.5,
        //     y: 1.2,
        //     w: 10,
        //     color: "000000",
        //     fontSize: 16,
        //   });
        //     const img = new Image();
        //     img.src = result.image_url;
        //     let new_width = img.width/img.height * 3.5
        //     slide.addImage({ path: result.image_url, x: 3, y: 2, w: new_width, h: 3.5 });


        //   return result;
      }
    });
    pptx.writeFile(filename);
  };

  responseToggle = () => {

    this.setState({
      responseModal: !this.state.responseModal,
    });

  };


  generateImage = () => {

    const node = document.getElementById("wordcloud-wrapper");

    domtoimage.toBlob(node, { bgcolor: 'white' }).then(dataUrl => {
      saveAs(dataUrl, 'WordCloud.png');
    }).catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
  };


  render() {
    const columns_groups = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Response",
        accessor: "response",
        filterable: false,
      },
      {
        Header: "Guest User",
        accessor: "public",
        filterable: false,
      },
      {
        Header: "Image",
        filterable: false,
        Cell: (activities) => (
          <Fragment>
            <button
              data-activity={JSON.stringify(activities.original)}
              onClick={this.onClickImage}
            >
              <img
                alt="activity content"
                src={activities.original.image_url}
                style={{ width: "120px" }}
              />
            </button>
          </Fragment>
        ),
      },
      {
        id: "date",
        Header: "Date",
        filterable: false,
        Cell: (activities) => (
          <Fragment>
            <label>
              {new Date(
                activities.original.createdAt.seconds * 1000
              ).toLocaleDateString("en-US")}
            </label>
          </Fragment>
        ),
      },
      {
        id: "options",
        Header: "Options",
        filterable: false,
        Cell: (activities) => (
          <Fragment>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                data-activity={JSON.stringify(activities.original)}
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#2a3381",
                  border: "none",
                }}
                onClick={this.checkDetails}
                className="btn btn-info btn-sm"
              >
                View Details
              </button>
              <button
                data-id={activities.original.id}
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  backgroundColor: "#DC3545",
                  border: "none",
                }}
                onClick={this.deleteResponse}
                className="btn btn-info btn-sm"
              >
                Delete
              </button>
            </div>
          </Fragment>
        ),
      },
    ];

    const columns_users = [
      {
        Header: "Group ID",
        accessor: "group_id",
      },
      {
        Header: "Response",
        accessor: "response",
        filterable: false,
      },
      {
        Header: "Image",
        filterable: false,
        Cell: (activities) => (
          <Fragment>
            <button
              data-activity={JSON.stringify(activities.original)}
              onClick={this.onClickImage}
            >
              <img
                alt="activity content"
                src={activities.original.image_url}
                style={{ width: "120px" }}
              />
            </button>
          </Fragment>
        ),
      },
      {
        Header: "Date",
        filterable: false,
        Cell: (activities) => (
          <Fragment>
            <label>
              {new Date(
                activities.original.createdAt.seconds * 1000
              ).toLocaleDateString("en-US")}
            </label>
          </Fragment>
        ),
      },
    ];

    const { activities } = this.props;
    console.log(activities)

    // debugger
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const slideShowModal = () => {


      if (activities[0] && activities[0].multipleQuestionResponse) {

        var slides_arr = [];

        activities[0].multipleQuestionResponse.answers.map((ans, ansIndex) => {

          activities.map((result, index) => {

            var obj = { question: ans.question };

            obj.image_url = result.multipleQuestionResponse.answers[ansIndex].image_url;
            obj.userName = result.name;
            slides_arr.push(obj);

          });

        });

        return (
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="modal-width"
          >
            <ModalHeader toggle={this.toggle}>Slide Show</ModalHeader>
            <button
              style={{
                backgroundColor: "#2a3381",
                border: "none",
                width: "min-content",
              }}
              className="btn btn-info ml-2 mt-2"
              onClick={this.downloadSlidesMultiple}
            >
              Download PPT
            </button>
            <ModalBody>
              <Slider {...settings}>
                {

                  slides_arr.map((slide, index) =>

                    <div key={index}>

                      <p>Question : {slide.question}</p>

                      <span className="image-author">{slide.userName}</span>

                      <img
                        className="mx-auto"
                        src={slide.image_url}
                        alt="slider"

                      />
                    </div>


                  )



                }
              </Slider>
            </ModalBody>
          </Modal>
        )
      } else {
        return (
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="modal-width"
          >
            <ModalHeader toggle={this.toggle}>Slide Show</ModalHeader>
            <button
              style={{
                backgroundColor: "#2a3381",
                border: "none",
                width: "min-content",
              }}
              className="btn btn-info ml-2 mt-2"
              onClick={this.downloadSlidesMultiple}
            >
              Download PPT
            </button>
            <ModalBody>
              <Slider {...settings}>
                {activities.map((result, index) => {
                  return (
                    <div key={index}>
                      <span className="image-author">{result.name}</span>
                      <img
                        className="mx-auto"
                        src={result.image_url}
                        alt="slider"

                      />
                    </div>
                  );
                })}
              </Slider>
            </ModalBody>
          </Modal>
        );
      }

    }
    let words = []

    const options = {
      colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
      enableTooltip: false,
      deterministic: true,
      fontFamily: "impact",
      fontSizes: [10, 60],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 1,
      rotations: 3,
      rotationAngles: [0, 90],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 1500,
      enableOptimizations: true
    }


    function removeDuplicates(originalArray, prop) {
      var newArray = [];
      var lookupObject = {};

      for (var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }

      for (i in lookupObject) {
        newArray.push(lookupObject[i]);
      }
      return newArray;
    }

    const responseModalRender = () => {


      if (activities[0] && activities[0].multipleQuestionResponse) {

        var words_arrs = [];

        activities[0].multipleQuestionResponse.answers.map((ans, index) => {

          var obj = { question: ans.question }

          var words = [];
          activities.map((result) => {


            var str = result.multipleQuestionResponse.answers[index].response;

            var new_val = str.split(" ");

            new_val.map((result_new) => {
              // remove unwanted words
              result_new = result_new.replaceAll(',', '');
              result_new = result_new.replaceAll('.', '');
              result_new = result_new.replaceAll('"', '');
              result_new = result_new.replaceAll('!', '');
              result_new = result_new.replaceAll(';', '');
              result_new = result_new.replaceAll(':', '');
              result_new = result_new.replaceAll('?', '');
              result_new = result_new.replaceAll('then', '');
              result_new = result_new.replaceAll('that', '');
              result_new = result_new.replaceAll('than', '');
              result_new = result_new.replaceAll('with', '');
              result_new = result_new.replaceAll('have’', '');
              result_new = result_new.replaceAll('has', '');
              result_new = result_new.replaceAll('this', '');
              result_new = result_new.replaceAll('that', '');
              result_new = result_new.replaceAll('what', '');

              if (result_new.length > 3) {

                words.push({
                  text: result_new,
                  value: Math.round(Math.random() * 10) + 10
                });;
              }
            })

          });

          words = removeDuplicates(words, "text");
          // console.log("uniqueArray is: " + JSON.stringify(words))

          obj.words = words;

          words_arrs.push(obj);

        });

        return (
          <Modal
            isOpen={this.state.responseModal}
            toggle={this.responseToggle}
            className="modal-width"
          >
            <ModalHeader toggle={this.responseToggle}>Wordcloud</ModalHeader>
            <ModalBody className="modal-height">
              <div className="app-outer mt-3" style={{ alignItems: 'normal', padding: '0px' }}>
                <button onClick={this.generateImage} style={{ backgroundColor: "rgb(42, 51, 129)", border: "none", width: "min-content" }} className="btn btn-info ml-2 mt-3">Download</button>

                <div className="app-inner" id="wordcloud-wrapper" style={{ backgroundColor: "white" }}>

                  {words_arrs.map((elem, index) =>
                    <>
                      <h5 className="p-2">Question {index + 1}: {elem.question}</h5>
                      <ReactWordcloud words={elem.words} options={options} />
                    </>
                  )
                  }

                </div>
              </div>
            </ModalBody>
          </Modal>
        )

      } else {
        return (
          <Modal
            isOpen={this.state.responseModal}
            toggle={this.responseToggle}
            className="modal-width"
          >
            <ModalHeader toggle={this.responseToggle}>Wordcloud</ModalHeader>
            <ModalBody className="modal-height">
              <div className="app-outer mt-3" style={{ alignItems: 'normal', padding: '0px' }}>
                <button onClick={this.generateImage} style={{ backgroundColor: "rgb(42, 51, 129)", border: "none", width: "min-content" }} className="btn btn-info ml-2 mt-3">Download</button>

                <div className="app-inner" id="wordcloud-wrapper" style={{ backgroundColor: "white" }}>

                  {
                    activities.map((result, index) => {
                      var str = result.response;
                      var new_val = str.split(" ");


                      new_val.map((result_new, index_new) => {

                        // remove unwanted words
                        result_new = result_new.replaceAll(',', '');
                        result_new = result_new.replaceAll('.', '');
                        result_new = result_new.replaceAll('"', '');
                        result_new = result_new.replaceAll('!', '');
                        result_new = result_new.replaceAll(';', '');
                        result_new = result_new.replaceAll(':', '');
                        result_new = result_new.replaceAll('?', '');
                        result_new = result_new.replaceAll('then', '');
                        result_new = result_new.replaceAll('that', '');
                        result_new = result_new.replaceAll('than', '');
                        result_new = result_new.replaceAll('with', '');
                        result_new = result_new.replaceAll('have’', '');
                        result_new = result_new.replaceAll('has', '');
                        result_new = result_new.replaceAll('this', '');
                        result_new = result_new.replaceAll('that', '');
                        result_new = result_new.replaceAll('what', '');



                        if (result_new.length > 3) {

                          words.push({
                            text: result_new,
                            value: Math.round(Math.random() * 10) + 10
                          });


                        }



                      })




                    }),



                    words = removeDuplicates(words, "text"),
                    console.log("uniqueArray is: " + JSON.stringify(words))


                  }

                  <ReactWordcloud words={words} options={options} />

                </div>
              </div>
            </ModalBody>
          </Modal>
        )
      }

    }

    const ViewQuestionModal = (
      <Modal isOpen={this.state.open} toggle={this.closeModal}>
        <ModalHeader toggle={this.closeModal}></ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <div id="main">
            <div className="form-padding-box">
              <div className="form-wrap">
                <div
                  className="item"
                  style={{ transition: "opacity 250ms ease-in" }}
                >
                  <div className="author-title">{this.state.question}</div>
                  <div className="msg-title" style={{ marginBottom: "38px" }}>
                    {this.state.response}{" "}
                  </div>
                  <div className="img-wrap" style={{ textAlign: "center" }}>
                    <img
                      style={{ width: "auto", maxHeight: "40vh" }}
                      src={this.state.image_url}
                      className="final-image"
                      alt="alt-final"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    );

    const ViewDetailModal = () => {
      return (
        <Modal isOpen={this.state.detailModal} toggle={this.checkDetails} >
          <ModalHeader toggle={this.checkDetails}>
            <h5>Detail Result</h5>
          </ModalHeader>
          <ModalBody>
            <div id="main" className="modal-body-content">
              <div className="row">
                <h6>Name:<span className="para2">&nbsp;{this.state.detailData.name}</span></h6>

              </div>
              <div className="row">
                <h6>Group ID:<span className="para2">&nbsp;{this.state.detailData.group_id}</span></h6>
              </div>

              {(this.state.detailData.multipleQuestionResponse && Array.isArray(this.state.detailData.multipleQuestionResponse.answers)) ?
                <>
                  {

                    this.state.detailData.multipleQuestionResponse.answers.map((answer, index) =>
                      <>

                        <div className="row">
                          <h6>Question {index + 1}:<span className="para2">&nbsp;{answer.question}</span>
                          </h6>
                        </div>
                        <div className="row">
                          <h6>Response {index + 1}:&nbsp;<span className="para2">{answer.response}</span></h6>

                        </div>
                        <div className="row img-center pt-4">
                          <img
                            src={answer.image_url}
                            width={300}
                            alt="avatar"
                          />
                        </div>
                        <hr />
                      </>
                    )

                  }
                </>
                :
                <>
                  <div className="row">
                    <h6>Question:<span className="para2">&nbsp;{this.state.detailData.question}</span>
                    </h6>
                  </div>
                  <div className="row">
                    <h6>Response:&nbsp;<span className="para2">{this.state.detailData.response}</span></h6>

                  </div>
                  <div className="row img-center pt-4">
                    <img
                      src={this.state.detailData.image_url}
                      width={300}
                      alt="avatar"
                    />
                  </div>
                </>
              }
            </div>
          </ModalBody>
        </Modal>
      )
    };

    var headers = [
      { label: "Name", key: "name" },
      { label: "Response", key: "response" },
      { label: "Guest User", key: "public" },
      { label: "Date", key: "date" },
    ];

    var csvData = this.props.activities;

    if (activities[0] && activities[0].multipleQuestionResponse) {
      headers = [];
      csvData = [];

      headers.push({ label: "Name", key: "name" });

      activities[0].multipleQuestionResponse.answers.map((ans, index) => {
        headers.push({ label: `${ans.question}`, key: `question${index}` });
      });

      headers.push({ label: "Guest User", key: "public" });
      headers.push({ label: "Date", key: "date" });


      activities.map((result) => {
        result.multipleQuestionResponse.answers.map((ans, index) => {

          result[`question${index}`] = result.multipleQuestionResponse.answers[index].response;

        });

        csvData.push(result);

      });


    }

    return (
      <div>
        <div>
          <Card style={{ marginTop: "30px" }}>
            <CardBody>
              <CSVLink
                filename={"GroupResults.csv"}
                style={{
                  margin: "3px",
                  float: "right",
                  backgroundColor: "#2a3381",
                  border: "none",
                }}
                className="btn btn-info"
                data={csvData}
                headers={headers}
              >
                Download CSV
              </CSVLink>
              <button
                style={{
                  margin: "3px",
                  backgroundColor: "#2a3381",
                  border: "none",
                }}
                className="btn btn-info btn-md float-right mr-2"
                onClick={this.toggle}
              >
                {"    "}
                View SlideShow
              </button>
              {slideShowModal()}
              <button
                style={{
                  margin: "3px",
                  backgroundColor: "#2a3381",
                  border: "none",
                }}
                className="btn btn-info btn-md float-right"
                onClick={this.responseToggle}
              >
                {responseModalRender()}
                {"    "}
                View wordcloud
              </button>
            </CardBody>
          </Card>
        </div>
        <div>
          {this.props.activities ? (
            <ReactTable
              data={this.props.activities}
              columns={
                this.props.query === "group_id" ? columns_groups : columns_users
              }
              defaultFilterMethod={(filter, row, column) => {
                const id = filter.pivotId || filter.id;
                if (typeof filter.value === "object") {
                  return row[id] !== undefined
                    ? filter.value.indexOf(row[id]) > -1
                    : true;
                } else {
                  return row[id] !== undefined
                    ? String(row[id]).indexOf(filter.value) > -1
                    : true;
                }
              }}
              className="-striped -highlight"
              filterable
              defaultPageSize={10}
              style={{ height: "600px" }}
            />
          ) : null}
        </div>
        {ViewQuestionModal}
        {ViewDetailModal()}
      </div>
    );
  }
}
let is_public = 0;
const setUsers = (activities, users) => {
  let new_activities = [];
  if (activities && users) {
    activities.forEach((activity_data, key) => {
      is_public = 0;

      var date = new Date(null);
      date.setTime(activity_data.createdAt.seconds * 1000);
      date = moment(date).format('MM/DD/YYYY');


      users.forEach((user_data, key) => {
        if (user_data.id === activity_data.user_id) {
          new_activities.push({
            ...activity_data,
            name: user_data.name,
            public: "No",
            date: date

          });
          is_public = 1;
        }
      });
      if (is_public === 0) {
        new_activities.push({
          ...activity_data,
          public: "Yes",
          date: date

        });
      }
    });
  }
  return new_activities;
};

const mapStateToProps = (props) => {
  const { firestore, group } = props;
  return {
    activities: setUsers(group.activities, group.users),
    group: group
  };
};
const mapDispatchToProps = (dispatch) => ({
  deleteResponse: (res_id) => dispatch(deleteResponse(res_id)),
  getActivittiesGroups: (query, key) => dispatch(getActivittiesGroups(query, key))
})



export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ViewResultsTable);
