import {
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_ERROR,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_ERROR,
  GET_GROUP_BY_ID_SUCCESS,
  GET_GROUP_BY_ID_ERROR,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_ERROR,
  UPDATE_GROUP_QUESTION_SUCCESS,
  UPDATE_GROUP_QUESTION_ERROR,
  DELETE_GROUP_RESPONSE,
  GET_ACTIVITIES,
  GET_USERS
} from "@app/action-types";

import { GroupModel } from "@app/models";
import history from "../history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export const refreshGroupErrors = (request) => async (dispatch) => {
  dispatch({ type: "" });
};

export const createGroup = (request) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();

  // console.log(GroupModel(request));
  try {
    const doc = await firestore.collection("groups").add(GroupModel(request))
      .then(() => {
        toast("Success! Group created", { type: "success" });
      });
    await doc;
    dispatch({ type: CREATE_GROUP_SUCCESS, payload: GroupModel(request) });
  } catch (error) {
    dispatch({ type: CREATE_GROUP_ERROR, error });
  }
};

export const deleteGroup = (id) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  try {
    firestore.delete({ collection: "groups", doc: id });
    dispatch({ type: DELETE_GROUP_SUCCESS });
  } catch (error) {
    dispatch({ type: DELETE_GROUP_ERROR, error });
  }
};

export const getActivittiesGroups = (query, resultsKey) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  try {

    let QuerySnapshot = await firestore.collection("activities").where(query, '==', resultsKey).get();
    dispatch({
      type: GET_ACTIVITIES,
      payload: QuerySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
    })
    QuerySnapshot = await firestore.collection("users").get();
    dispatch({
      type: GET_USERS,
      payload: QuerySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
    })

  } catch (error) {
    console.log(error)
  }
};

export const deleteResponse = (id) => (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  try {
    firestore.delete({ collection: "activities", doc: id });
    dispatch({ type: DELETE_GROUP_RESPONSE });
  } catch (err) {
    dispatch({ type: DELETE_GROUP_ERROR, err });
  }
};

export const getGroup = () => async (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore();
  // where("userId", "==", getState().firebase.auth.uid)
  if (getState().firebase.auth.email != 'zzohaibshaheen@gmail.com' && getState().firebase.auth.email != 'consulting@theflux.com') {

    try {
      const querySnapshot = await firestore.collection("groups").where("userId", "==", getState().firebase.auth.uid).get();
      dispatch({
        type: GET_GROUPS_SUCCESS,
        payload: querySnapshot.docs.map((doc) => doc.data()),
      });
      return querySnapshot;
    } catch (error) {
      dispatch({ type: GET_GROUPS_ERROR, error });
    }
  } else {

    try {
      const querySnapshot = await firestore.collection("groups").get();
      dispatch({
        type: GET_GROUPS_SUCCESS,
        payload: querySnapshot.docs.map((doc) => doc.data()),
      });
      return querySnapshot;
    } catch (error) {
      dispatch({ type: GET_GROUPS_ERROR, error });
    }
  }
};

export const getGroupByGroupKey = (groupKey) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const groupQuerySnapshot = await getFirestore()
        .collection("groups")
        .where("groupKey", "==", groupKey)
        .get();
      const group = await groupQuerySnapshot.docs.map((doc) => doc.data());

      if (group.length === 0) {
        dispatch({ type: GET_GROUP_BY_ID_ERROR, error: "Group ID not found!" });
      } else {
        const activityQuerySnapshot = await getFirestore()
          .collection("activities")
          .where("activity_type", "==", "group")
          .where("group_id", "==", groupKey)
          .where("user_id", "==", getState().firebase.auth.uid)
          .get();
        const activity = await activityQuerySnapshot.docs.map((doc) =>
          doc.data()
        );
        if (activity.length === 0) {
          window.localStorage.setItem("group", JSON.stringify(group));

          // Check if question_id exists and get the count
          const questionCount = (group && group.question_id && group.question_id.length) || 0;
          window.localStorage.setItem("question_total", questionCount);


          history.push("/team-activity/question");
          dispatch({ type: GET_GROUP_BY_ID_SUCCESS, payload: group });
        } else {
          dispatch({
            type: GET_GROUP_BY_ID_ERROR,
            error: "You've already used this Group ID.",
          });
        }
      }
    } catch (error) {
      dispatch({ type: GET_GROUP_BY_ID_ERROR, error });
    }
  };
};

export const updateGroup = (group) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  getFirestore()
    .collection("groups")
    .doc(group.id)
    .update({ groupName: group.groupName, question_id: group.question_id, collection: group.collection })
    .then(() => {
      dispatch({ type: UPDATE_GROUP_SUCCESS });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_GROUP_ERROR, err });
    });
};

export const setGroupQuestion = (groupKey, question_id) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  getFirestore()
    .collection("groups")
    .where("groupKey", "==", groupKey)
    .get()
    .then(({ docs }) => {
      return getFirestore()
        .collection("groups")
        .doc(docs[0].id)
        .update({ question_id })
        .then(() => {
          dispatch({
            type: UPDATE_GROUP_QUESTION_SUCCESS,
            payload: { groupKey, question_id },
          });
        });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_GROUP_QUESTION_ERROR, err });
    });
};


// export const getGroupForRandomUser = (groupKeyId) => {
//   return async (dispatch, { getFirestore }) => {
//     try {
//       const groupQuerySnapshot = await getFirestore()
//         .collection("groups")
//         .where("groupKey", "==", groupKeyId)
//         .get();
//       const group = await groupQuerySnapshot.docs.map((doc) => doc.data());

//       if (group.length === 0) {
//         dispatch({ type: GUEST_GROUP_BY_ID_ERROR, error: "Group ID not found!" });
//       } else {
//         const activityQuerySnapshot = await getFirestore()
//           .collection("activities")
//           .where("activity_type", "==", "group")
//           .where("group_id", "==", groupKeyId)
//           // .where("user_id", "==", getState().firebase.auth.uid)
//           .get();
//         const activity = await activityQuerySnapshot.docs.map((doc) =>
//           doc.data()
//         );
//         if (activity.length === 0) {
//           window.localStorage.setItem("group", JSON.stringify(group));
//           history.push("/guest-activity/question");
//           dispatch({ type: GUEST_GROUP_BY_ID_SUCCESS, payload: group });
//         } else {
//           dispatch({
//             type: GUEST_GROUP_BY_ID_ERROR,
//             error: "You've already used this Group ID.",
//           });
//         }
//       }
//     } catch (error) {
//       dispatch({ type: GUEST_GROUP_BY_ID_ERROR, error });
//     }
//   };
// };


export const getGroupForRandomUser = (groupKey) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const groupQuerySnapshot = await getFirestore()
        .collection("groups")
        .where("groupKey", "==", groupKey)
        .where("allow_public_response", "==", "yes")
        .get();
      const group = await groupQuerySnapshot.docs.map((doc) => doc.data());

      if (group.length === 0) {
        dispatch({ type: GET_GROUP_BY_ID_ERROR, error: "Group ID not found!" });
      } else {

        window.localStorage.setItem("group", JSON.stringify(group));
        history.push("/guest-activity/question");
        dispatch({ type: GET_GROUP_BY_ID_SUCCESS, payload: group });

      }
    } catch (error) {
      dispatch({ type: GET_GROUP_BY_ID_ERROR, error });
    }
  };
};
