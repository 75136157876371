import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';

class ResponsePageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideNameFromGroup: false,
            hideResponseFromGroup: false,
        }
    }

    handleInputChange = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.checked,
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        delete this.props.responsePageState.hideNameFromGroup;
        delete this.props.responsePageState.hideResponseFromGroup;

        const request = {
            hideNameFromGroup: this.state.hideNameFromGroup,
            hideResponseFromGroup: this.state.hideResponseFromGroup,
            ...this.props.responsePageState,
        }

        if (!Array.isArray(JSON.parse(window.localStorage.getItem("group"))[0].question_id)) {

            request.multipleQuestionResponse = null;
            this.props.saveActivity(request);

        } else {

            // Retrieve the `multipleQuestionResponse` object from localStorage
            var multipleQuestionResponse = JSON.parse(localStorage.getItem("multipleQuestionResponse"));

            // Define the total expected number of answers
            var total = localStorage.getItem("question_total");

            // Check if the answers count matches the total
            if (multipleQuestionResponse && multipleQuestionResponse.answers) {
                var answersCount = multipleQuestionResponse.answers.length;

                if (answersCount > total) {
                    // Remove excess answers from the end of the array
                    multipleQuestionResponse.answers = multipleQuestionResponse.answers.slice(0, total);
                } else if (answersCount < total) {
                    console.log(`Answers count (${answersCount}) is less than the total (${total}).`);
                }

                // Save the updated object back to localStorage
                localStorage.setItem("multipleQuestionResponse", JSON.stringify(multipleQuestionResponse));
            }

            var multipleQuestionResponse = JSON.parse(localStorage.getItem("multipleQuestionResponse"));

            if (multipleQuestionResponse.group_id == this.props.responsePageState.group_id) {

                request.multipleQuestionResponse = multipleQuestionResponse;

            }

            this.props.saveActivity(request);

        }


    }
    // onClose={this.props.handleModal}
    render() {
        return (
            <Modal open={this.props.open} showCloseIcon={false} onClose={() => { }}>
                <div styles={{ width: `670px`, margin: '20px' }}>
                    <div className="resp-title" style={{ textAlign: 'center', margin: '30px 30px 2px 30px' }}>
                        GROUP RESULTS DISPLAY
                    </div>
                    <div style={{ margin: '10px 10px 30px 10px', fontSize: '14px', }}>
                        <div className="confirm-box">
                            <input type="checkbox" id="hideNameFromGroup" name="hideNameFromGroup"
                                onChange={(e) => { this.setState({ hideNameFromGroup: !this.state.hideNameFromGroup }) }} />
                            <label htmlFor="hideNameFromGroup">Hide my name from group display</label>
                        </div>
                        <div className="confirm-box">
                            <input type="checkbox" id="hideResponseFromGroup" name="hideResponseFromGroup"
                                onChange={(e) => { this.setState({ hideResponseFromGroup: !this.state.hideResponseFromGroup }) }} />
                            <label htmlFor="hideResponseFromGroup">Hide my response from group display</label>
                        </div>
                    </div>
                    <div className="button-wrap">
                        <button className="btn-primary active" onClick={this.handleSubmit}>VIEW RESULTS</button>
                    </div>
                </div>
            </Modal>
        )
    }
}

ResponsePageModal.propTypes = {
    open: PropTypes.bool,
    handleModal: PropTypes.func,
    saveActivity: PropTypes.func,
    responsePageState: PropTypes.object,
}

export default ResponsePageModal;